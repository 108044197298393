import { RedirectionCampaignConstant } from '../constant';
const initialState = {
  payload: null,
  statusUpdateCampaign: null,
  type: '',
  message: '',
  contentExperience: {},
  isGeneral: false,
  abTestingDetailCopy: {},
  updatedDefaultEntries: [],
  personalizationDetailCopy: {},
  loading: false,
};

export default function redirectionCampaignReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case RedirectionCampaignConstant.UPDATECAMPAIGNSUCCESS:
      return {
        ...state,
        updatedDefaultEntries: action.payload,
      };
    case RedirectionCampaignConstant.GETREDIRECTIONCAMPAIGNLISTSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.GETREDIRECTIONCAMPAIGNLISTFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.SAVECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.SAVECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.DELETECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.DELETECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.CHANGECAMPAIGNPRIORITYSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.CHANGECAMPAIGNPRIORITYFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case RedirectionCampaignConstant.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case RedirectionCampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    case RedirectionCampaignConstant.UPDATE_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        type: action.type,
        message: action.message,
        statusUpdateCampaign: action.payload,
      };
    case RedirectionCampaignConstant.UPDATE_CAMPAIGN_STATUS_FAIL:
      return {
        ...state,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.CLEAR_ALL_DATA:
      return initialState;

    // eslint-disable-next-line no-duplicate-case
    case RedirectionCampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
}
