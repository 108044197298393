import { ContentCampaignListConstant } from '../constant/contentCampaignListConstant';
const initialState = {
  payload: null,
  statusUpdateCampaign: null,
  type: '',
  message: '',
  contentExperience: {},
  isGeneral: false,
  abTestingDetailCopy: {},
  updatedDefaultEntries: [],
  personalizationDetailCopy: {},
  loading: false,
};

export default function contentCampaignListReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case ContentCampaignListConstant.UPDATECAMPAIGNSUCCESS:
      return {
        ...state,
        updatedDefaultEntries: action.payload,
      };
    case ContentCampaignListConstant.GETCAMPAIGNLISTSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.GETCAMPAIGNLISTFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.SAVECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.SAVECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.DELETECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.DELETECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.CHANGECAMPAIGNPRIORITYSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.CHANGECAMPAIGNPRIORITYFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.SAVECONTENTABTESTCAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.SAVECONTENTABTESTCAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_FAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case ContentCampaignListConstant.GET_CONTENT_EXPERINCE_SUCCESS:
      return {
        ...state,
        abTestingDetail: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.GET_CONTENT_EXPERINCE_COPY_SUCCESS:
      return {
        ...state,
        abTestingDetail: action.payload,
        type: ContentCampaignListConstant.GET_CONTENT_EXPERINCE_SUCCESS,
        message: action.message,
        description: action.description,
        abTestingDetailCopy: action.payload,
      };

    case ContentCampaignListConstant.GET_CONTENT_EXPERINCE_FAIL:
      return {
        ...state,
        abTestingDetail: null,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.GET_CONTENT_PERSONALIZATION_SUCCESS:
      return {
        ...state,
        personalizationDetail: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.UPDATE_CONTENT_STATUS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.UPDATE_CONTENT_STATUS_FAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };

    case ContentCampaignListConstant.GET_CONTENT_PERSONALIZATION_COPY_SUCCESS:
      return {
        ...state,
        personalizationDetailCopy: action.payload,
        personalizationDetail: action.payload,
        type: ContentCampaignListConstant.GET_CONTENT_PERSONALIZATION_SUCCESS,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.GET_CONTENT_PERSONALIZATION_FAIL:
      return {
        ...state,
        personalizationDetail: null,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.CLEAR_DATA:
      return {
        ...state,
        type: action.type,
      };
    case ContentCampaignListConstant.DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ContentCampaignListConstant.DELETE_CONTENT_FAIL:
      return {
        ...state,
        type: action.type,
      };
    case ContentCampaignListConstant.SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case ContentCampaignListConstant.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case ContentCampaignListConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    case ContentCampaignListConstant.UPDATE_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        type: action.type,
        message: action.message,
        statusUpdateCampaign: action.payload,
      };
    case ContentCampaignListConstant.UPDATE_CAMPAIGN_STATUS_FAIL:
      return {
        ...state,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case ContentCampaignListConstant.CLEAR_ALL_DATA:
      return initialState;

    // eslint-disable-next-line no-duplicate-case
    case ContentCampaignListConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
}
