export const roleConstants = {
  ROLE_GETALL_REQUEST: 'ROLE_GETALL_REQUEST',
  ROLE_GETALL_SUCCESS: 'ROLE_GETALL_SUCCESS',
  ROLE_GETALL_FAILURE: 'ROLE_GETALL_FAILURE',

  ROLE_SAVE_REQUEST: 'ROLE_SAVE_REQUEST',
  ROLE_SAVE_SUCCESS: 'ROLE_SAVE_SUCCESS',
  ROLE_SAVE_FAILURE: 'ROLE_SAVE_FAILURE',

  ROLE_UPDATE_REQUEST: 'ROLE_UPDATE_REQUEST',
  ROLE_UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
  ROLE_UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE',

  ROLE_DELETE_REQUEST: 'ROLE_DELETE_REQUEST',
  ROLE_DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
  ROLE_DELETE_FAILURE: 'ROLE_DELETE_FAILURE',

  DEFAULTERR: 'An error occurred. Please try again.',
};
