export const ContentCampaignListConstant = {
  SET_LOADING_TRUE: 'SET_LOADING_TRUE',
  SET_LOADING_FALSE: 'SET_LOADING_FALSE',

  GET_CONTENT_PERSONALIZATION_COPY_SUCCESS:
    'GET_CONTENT_PERSONALIZATION_COPY_SUCCESS',
  GET_CONTENT_PERSONALIZATION_FAIL: 'GET_CONTENT_PERSONALIZATION_FAIL',

  GET_CONTENT_EXPERINCE_COPY_SUCCESS: 'GET_CONTENT_EXPERINCE_COPY_SUCCESS',
  GET_CONTENT_EXPERINCE_FAIL: 'GET_CONTENT_EXPERINCE_FAIL',

  CLEAR_ALL_DATA: 'CLEAR_ALL_DATA',
  DEFAULTERR: 'Something went wrong. Please try again later.',
};
