import { userConstants } from '../constant/userConstant';

const initialState = {
  loading: false,
  data: [],
  error: null,
  message: '',
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.USER_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.USER_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        message: action.message,
      };
    case userConstants.USER_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
      };
    case userConstants.USER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.USER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((user) => user.id.username !== action.payload),
        message: action.message,
      };
    case userConstants.USER_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
      };
    default:
      return state;
  }
};
