export const RedirectionCampaignConstant = {
  CLEAR_TYPE: 'CLEAR_TYPE',
  DEFAULTERR: 'sorry unable to process! please try again later',

  SAVECAMPAIGNSUCCESS: 'SAVE_CAMPAIGN_SUCCESS',
  SAVECAMPAIGNFAIL: 'SAVE_CAMPAIGN_FAIL',

  SET_LOADING_TRUE: 'SET_LOADING_TRUE',
  SET_LOADING_FALSE: 'SET_LOADING_FALSE',

  GETREDIRECTIONCAMPAIGNLISTSUCCESS: 'GET_CAMPAIGN_LIST_SUCCESS',
  GETREDIRECTIONCAMPAIGNLISTFAIL: 'GET_CAMPAIGN_LIST_FAIL',

  UPDATECAMPAIGNSUCCESS: 'UPDATE_CAMPAIGN_SUCCESS',

  DELETECAMPAIGNSUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
  DELETECAMPAIGNFAIL: 'DELETE_CAMPAIGN_FAIL',

  CHANGECAMPAIGNPRIORITYSUCCESS: 'CHANGE_CAMPAIGN_PRIORITY_SUCCESS',
  CHANGECAMPAIGNPRIORITYFAIL: 'CHANGE_CAMPAIGN_PRIORITY_FAIL',

  UPDATE_CAMPAIGN_STATUS_SUCCESS: 'UPDATE_CAMPAIGN_STATUS_SUCCESS',
  UPDATE_CAMPAIGN_STATUS_FAIL: 'UPDATE_CAMPAIGN_STATUS_FAIL',

  CLEAR_ALL_DATA: 'CLEAR_ALL_DATA',
};
