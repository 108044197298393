export const fieldConstant = {
  DEFAULTERR: 'sorry unable to process! please try again later',

  SETGENERAL: 'SET_GENERAL',
  SETPANEL: 'SET_PANEL',
  SETINDEPENDENTPERSONALIZATION: 'SET_INDEPENDENT_PERSONALIZATION',
  SETCONTENTABTESTING: 'SET_CONTENT_AB_TESTING',
  SETISINDORABT: 'SET_ISINDORABT',

  ADDABCONTENT: 'ADD_AB_CONTENT',
  SETDEFAULTTRAFFIC: 'SET_DEFAULT_TRAFFIC',

  ADDEXPERIENCE: 'ADD_EXPERIENCE',
  UPDATEEXPERIENCE: 'UPDATE_EXPERIENCE',
  REMOVEEXPERIENCE: 'REMOVE_EXPERIENCE',

  ADDFEILDSEGMENT: 'ADD_FEILD_SEGMENT',
  UPDATEFEILDSEGMENT: 'UPDATE_FEILD_SEGMENT',
  REMOVEFEILDSEGMENT: 'REMOVE_FEILD_SEGMENT',

  DELETEENTRYFROMSEGMENT: 'DELETE_ENTRY_FROM_SEGMENT',
  SETABTESTSTATUSFORSEGMENT: 'SET_AB_TEST_STATUS_FOR_SEGMENT',
  SETABTESTTRAFFICFORSEGMENT: 'SET_AB_TEST_TRAFFIC_FOR_SEGMENT',

  CLEARFIELDALL: 'CLEAR_FIELD_ALL',

  SETSEGMENTLIST: 'SET_SEGMENT_LIST',

  SAVEABTESTING: 'SAVEABTESTING',
  SETSEGMENTRULE: 'SETSEGMENTRULE',
  DRAFTVARIENTS: 'DRAFTVARIENTS',

  STORE_CAMPAIGN_NAME: 'STORE_CAMPAIGN_NAME',
};
