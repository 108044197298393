export const userRoleConstants = {
  USER_ROLE_ADD_REQUEST: 'USER_ROLE_ADD_REQUEST',
  USER_ROLE_ADD_SUCCESS: 'USER_ROLE_ADD_SUCCESS',
  USER_ROLE_ADD_FAILURE: 'USER_ROLE_ADD_FAILURE',

  USER_ROLE_UPDATE_REQUEST: 'USER_ROLE_UPDATE_REQUEST',
  USER_ROLE_UPDATE_SUCCESS: 'USER_ROLE_UPDATE_SUCCESS',
  USER_ROLE_UPDATE_FAILURE: 'USER_ROLE_UPDATE_FAILURE',

  DEFAULTERR: 'An error occurred while processing your request',
};
