import { getAllCampaignConstant } from '../constant/getAllCampaignConstant';

const getActionCount = (action) =>
  action.count !== undefined ? action.count : 0;

const initialState = {
  payload: null,
  statusUpdateCampaign: null,
  type: '',
  message: '',
  contentExperience: {},
  isGeneral: false,
  abTestingDetailCopy: [],
  updatedDefaultEntries: [],
  personalizationDetailCopy: [],
  loading: false,
  count: 0,
};

export default function getAllCampaignReducer(state = initialState, action) {
  switch (action.type) {
    case getAllCampaignConstant.FETCH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        loading: true,
        count: getActionCount(action),
        type: action.type,
      };
    case getAllCampaignConstant.FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        count: getActionCount(action),
        loading: false,
        type: action.type,
      };
    case getAllCampaignConstant.FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        payload: [],
        loading: false,
        count: 0,
        message: action.error,
        type: action.type,
      };

    // Handle FETCH A/B TESTS
    case getAllCampaignConstant.FETCH_AB_TESTS_REQUEST:
      return {
        ...state,
        loading: true,
        count: getActionCount(action),
        type: action.type,
      };
    case getAllCampaignConstant.FETCH_AB_TESTS_SUCCESS:
      return {
        ...state,
        abTestingDetailCopy: action.payload,
        loading: false,
        count: getActionCount(action),
        type: action.type,
      };
    case getAllCampaignConstant.FETCH_AB_TESTS_FAILURE:
      return {
        ...state,
        abTestingDetailCopy: [],
        loading: false,
        count: 0,
        message: action.error,
        type: action.type,
      };

    // Handle FETCH PERSONALIZATIONS
    case getAllCampaignConstant.FETCH_PERSONALIZATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        count: getActionCount(action),
        type: action.type,
      };
    case getAllCampaignConstant.FETCH_PERSONALIZATIONS_SUCCESS:
      return {
        ...state,
        personalizationDetailCopy: action.payload,
        loading: false,
        count: getActionCount(action),
        type: action.type,
      };
    case getAllCampaignConstant.FETCH_PERSONALIZATIONS_FAILURE:
      return {
        ...state,
        personalizationDetailCopy: [],
        loading: false,
        count: 0,
        message: action.error,
        type: action.type,
      };

    // Clear Data
    case getAllCampaignConstant.CLEAR_DATA:
      return {
        ...state,
        type: action.type,
      };

    // Set Loading States
    case getAllCampaignConstant.SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };

    case getAllCampaignConstant.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    // Clear Type
    case getAllCampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };

    // Clear All Data (reset to initialState)
    case getAllCampaignConstant.CLEAR_ALL_DATA:
      return initialState;

    default:
      return state;
  }
}
